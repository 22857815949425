.cv {
  position: absolute;
  font-family: $font-2;
  user-select: none;
  top: 90%;
  left: 3%;
  transition: 0.4s ease-in-out;
  z-index: 1000;

  &:hover {
    transform: scale(1.1);
  }

  @media screen and (max-width: 900px) {
    top: 93%;
  }

  i {
    font-size: 2rem;
    color: $color-4;
  }
}
