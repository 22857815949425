.scroll {
  a {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    font-size: 3.5rem;
    color: rgb(184, 183, 183);
    transition: 0.25s;
    user-select: none;
    padding: 0 14px;

    &:hover {
      font-size: 4.5rem;
    }

    @media screen and (max-width: 430px) {
      font-size: 2rem;
    }
  }

  .left {
    left: 5%;

    @media screen and (max-width: 430px) {
      left: 3%;
    }
  }

  .right {
    left: 95%;

    @media screen and (max-width: 430px) {
      left: 97%;
    }
  }

  .bot {
    transform: rotate(90deg);
    left: 50%;
    top: 80%;
    @media screen and (max-width: 430px) {
      top: 80%;
    }
  }

  .top {
    transform: rotate(-90deg);
    left: 50%;
    top: 0%;
  }
}
