.cursor {
  width: 62px;
  height: 62px;
  border: 1px solid $color-2;
  border-radius: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  pointer-events: none;
  transition: 500ms ease-out;
  z-index: 2;
  backdrop-filter: brightness(115%);

  &.hovered {
    width: 30px;
    height: 30px;
  }
}
